import { styled } from "linaria/react";
import { css } from "linaria";
import { DefaultCard } from "components/rhombus-UI/theme/containers";

export const EventContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: max-content;
  @media (max-width: 700px) {
    justify-content: center;
  }
`;

export const trainingSessionSlide = css`
  width: 400px !important;
  height: 450px !important;
  max-width: 100% !important;
  padding: 0;
  margin: 0;
  .card {
    width: 100%;
    height: 100%;
    .card-inner {
      height: calc(25% - 2.5rem) !important;
    }
  }
  :hover .card .card-inner {
    height: 100% !important;
  }

  @media (min-width: 1440px) {
    width: calc((100% - 5rem) / 3) !important;
  }
  @media (max-width: 1320px) {
    width: calc((100% - 2.5rem) / 2) !important;
  }
  @media (max-width: 700px) {
    width: 100% !important;
    max-width: 400px !important;
    .card .card-inner {
      gap: 2.5rem !important;
      height: fit-content !important;
    }
  }
`;

export const TrainingSessionCard = styled(DefaultCard)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 250ms ease-in-out;
  position: relative;
  color: var(--white);
  background-color: var(--blue-900);
  pointer-events: ${props => (props.booked ? "none" : "unset")};
  &::after {
    content: "";
    position: absolute;
    height: ${props => (props.booked ? "100%" : "0")};
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: hsl(from var(--nuetral-300) h s l / 50%);
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    object-position: bottom;
  }

  .card-inner {
    height: 25%;
    background: hsl(from var(--blue-900) h s l / 77%);
    z-index: 1;
    padding: 1.75rem;
    transition: all 250ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4rem;
  }
  :hover .card-inner {
    height: 100%;
  }
  @media (max-width: 700px) {
    .card-inner {
      gap: 2.5rem;
      height: fit-content;
      .title-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
`;

export const EventTag = styled.div`
  position: absolute;
  top: 1.75rem;
  right: 0;
  padding: 0 1.25rem;
  background: var(--gradient-base);
  z-index: 3;
  border-radius: 2rem 0 0 2rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  p {
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const BookedEventTag = styled.div`
  position: absolute;
  top: 1.75rem;
  right: 0;
  padding: 0 1.25rem;
  background: var(--nuetral-500);
  z-index: 3;
  border-radius: 2rem 0 0 2rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  p {
    font-weight: 600;
    text-transform: uppercase;
  }
`;
