import EventLocationsHero from "components/RCTP-Program/EventLocationsHero";
import RhombusBlade from "components/common/RhombusBlade";
import GlobalLayout from "components/page/GlobalLayout";

import React from "react";
import Helmet from "react-helmet";
import TrainingEventFeed from "components/RCTP-Program/TrainingEventFeed";

export default function TrainingEventLocations() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Upcoming Trainings - Rhombus Certified Technical Professional
        </title>
        <meta
          name="description"
          content="Find a location near you to register for our RCTP Training and become certified as a Rhombus technical expert."
        />
      </Helmet>
      <EventLocationsHero />
      <TrainingEventFeed />
      <RhombusBlade
        fancy
        reverse
        title="Becoming a Partner is Simple"
        blurb="Submit your application online in 5 minutes, and our Partner Team will reach out within two business days."
        button={{
          text: "Apply",
          path:
            "https://partners.rhombussystems.com/prm/English/c/Terms?__hstc=158651301.11b9a359e50e4a16823c8ed728407db3.1691694797463.1692043226007.1692120531447.10&__hssc=158651301.9.1692120531447&__hsfp=3529731725",
          useHref: true,
          target: "_blank",
        }}
      />
    </GlobalLayout>
  );
}
