import React from "react";
import { styled } from "linaria/react";
import { css } from "linaria";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import BackLink from "components/common/BackLink";
import { StaticImage } from "gatsby-plugin-image";
import RhombusButton from "components/common/RhombusButton";

const HeroContainer = styled(SectionContainer)`
  background: var(--gradient-light);
`;

const HeroInner = styled(SectionInner)`
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const HeroLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (max-width: 820px) {
    width: 100%;
  }
`;

const HeroRight = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 820px) {
    width: 100%;
    position: relative;
  }
`;

const imageStyles = css`
  width: 695px;
  height: 623px;
  object-fit: cover;
  overflow: hidden;
  margin-right: -7.5rem;
  border-radius: 1.25rem;
  @media (min-width: 1600px) {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
  @media (max-width: 820px) {
    height: 300px;
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: 600px) {
    height: auto;
    width: 100%;
  }
`;

export default function EventLocationsHero() {
  return (
    <HeroContainer>
      <HeroInner>
        <HeroLeft>
          <BackLink path="/rctp-program/" text="RTCP Program" />
          <TextContainer>
            <PreTitle>Event Locations</PreTitle>
            <FancyPageTitle>Upcoming RCTP Training Sessions </FancyPageTitle>
            <MainParagraph>
              Elevate your customer offerings and establish yourself as a
              security leader with Rhombus.
            </MainParagraph>
            <RhombusButton
              title="Browse Locations"
              path="#event-locations"
              type="primary"
              style={{ marginTop: "1.25rem" }}
            />
          </TextContainer>
        </HeroLeft>
        <HeroRight>
          <StaticImage
            src="./img/event-locations.png"
            alt="Rhombus Certified Technical Professional"
            className={imageStyles}
          />
        </HeroRight>
      </HeroInner>
    </HeroContainer>
  );
}
